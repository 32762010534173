import {useQuery} from 'react-query';
import {getMyOrganization, getSubscriptionPricing} from '../Calls/Onboarding';
import {
  checkForDuplicateEmail,
  checkForExistingEmailDomain,
  checkForDuplicateOrg,
  getOrgUsers,
  getPropublicaNonprofitSearch,
  getPropublicaOrg,
  getSearchPropublica,
  getSetupIntent,
  getSubscriptionPaymentMethod,
  listSubscriptionInvoices,
} from '../Calls/OrgAdmin';

interface UseSubscriptionPricingOptions {
  enabled?: boolean;
}

export const useGetMyOrganization = (enabled = true, refetchInterval: number | false = false) => {
  return useQuery(['ONBOARDING', 'ORGANIZATION'], getMyOrganization, {
    enabled: enabled,
    refetchInterval: refetchInterval || false,
  });
};

export const useSubscriptionPricing = (options?: UseSubscriptionPricingOptions) =>
  useQuery(['SUBSCRIPTION', 'PRICING'], getSubscriptionPricing, {
    enabled: options?.enabled ?? true,
  });

export const useGetOrgUsers = () => useQuery(['ONBOARDING', 'USERS'], getOrgUsers);

export const useListSubscriptionInvoices = () => useQuery(['ONBOARDING', 'INVOICES'], listSubscriptionInvoices);

export const useGetSubscriptionPaymentMethod = () => useQuery(['ONBOARDING', 'PAYMENTMOETHOD'], getSubscriptionPaymentMethod);

export const useGetSetupIntent = () => useQuery(['ONBOARDING', 'SETUPINTENT'], getSetupIntent);

export const usePropublicaNonprofitSearch = (params: any) =>
  useQuery(['ONBOARDING', 'NONPROFITS'], () => getPropublicaNonprofitSearch(params));

export const useSearchPropublica = (filter: string) =>
  useQuery(['ONBOARDING', 'NONPROFITS', filter], () => getSearchPropublica({q: filter}), {enabled: Boolean(filter)});

export const useGetPropublicaOrg = (ein: number) =>
  useQuery(['ONBOARDING', 'NONPROFITS', ein], () => getPropublicaOrg({ein}), {enabled: Boolean(ein)});

export const useCheckForDuplicateOrg = (organizationName: string) =>
  useQuery(['DUPORGNAME', organizationName], () => checkForDuplicateOrg({organizationName}), {
    enabled: Boolean(organizationName),
  });

export const useCheckForDuplicateEmail = (email: string, organizationId: string) =>
  useQuery(['DUPEEMAIL', email], () => checkForDuplicateEmail({email, organizationId}), {
    enabled: Boolean(email) && Boolean(organizationId),
  });

export const useCheckForExistingEmailDomain = (email: string = '') =>
  useQuery(['DUPEDOMAIN', email], () => checkForExistingEmailDomain({email}), {
    enabled: Boolean(email),
  });
