import {useEffect, useState} from 'react';
import {useCheckForExistingEmailDomain, useGetMyOrganization, useSubscriptionPricing} from '../../API';
import {useMyProfile} from '../../API/Queries/useMyProfile';
import {STRIPE_STATUS_MESSAGES} from '../../constants/constants';
import {routePaths} from '../../constants/routePaths';
import {useCartOrganization} from '../../Hooks/useCartOrganization';
import {useRoles} from '../../Hooks/useRoles';

// Custom hook to determine the status route for the user
export const useOnboardingRoute = () => {
  const [routePath, setRoutePath] = useState<string>();

  const {isOrgAdmin, isAdmin, isLoading: isLoadingRoles} = useRoles();

  const {data: subscriptionPricing, isLoading: isLoadingPricing} = useSubscriptionPricing();
  const {data: myProfile, isFetching: isLoadingProfile} = useMyProfile();
  const {data: accountOrganization, isLoading: isLoadingOrganization} = useGetMyOrganization(!!myProfile?.user?.organizationId);

  const {data: duplicateEmailDomainCheck, isLoading: isLoadingExistingEmailDomainCheck} = useCheckForExistingEmailDomain(
    myProfile?.user?.email || '',
  );

  const hasExistingDomain = !!duplicateEmailDomainCheck?.organization;

  const {organization: cartOrganization} = useCartOrganization();

  const subscriptionTypes = subscriptionPricing?.subscriptionTypes;
  const isLocalOrg =
    cartOrganization?.subscription_type === subscriptionTypes?.local?.value ||
    accountOrganization?.subscription_type === subscriptionTypes?.local?.value;

  const isLoading = isLoadingRoles || isLoadingProfile || isLoadingOrganization || isLoadingPricing || isLoadingExistingEmailDomainCheck;

  const isFirstTime = !myProfile?.user?.onboarding_completed;

  const orgHasLocations = !!cartOrganization?.authorizations?.length;

  const hasActiveSubscription =
    !!accountOrganization?.subscription &&
    (accountOrganization?.subscription?.status === 'active' || accountOrganization?.subscription?.status === 'trialing');

  const hasIncompletePayment =
    accountOrganization?.subscription?.status === STRIPE_STATUS_MESSAGES.incomplete.value ||
    accountOrganization?.subscription?.status === STRIPE_STATUS_MESSAGES.incomplete_expired.value;

  const orgHasMembers = !!cartOrganization?.users?.length && cartOrganization?.users?.length > 1;

  const userIsActive = myProfile?.user?.user_status === 'active';

  useEffect(() => {
    if (isLoading) {
      return;
    }

    // Greenlink staff should always go to the map
    if (isAdmin || (!isFirstTime && hasActiveSubscription)) {
      setRoutePath(routePaths.map);
      return;
    }

    // organization admins need routing
    if (isOrgAdmin && !hasActiveSubscription) {
      if (hasExistingDomain) {
        return;
      }

      if (hasIncompletePayment) {
        setRoutePath(routePaths.orgAdminSubscription);
        return;
      }

      if (isLocalOrg) {
        setRoutePath(routePaths.onboardingLocal);
        return;
      }

      if (
        !cartOrganization?.name ||
        !cartOrganization?.subscription_type ||
        !cartOrganization?.address1 ||
        !cartOrganization?.city ||
        !cartOrganization?.state ||
        !cartOrganization?.zip
      ) {
        setRoutePath(routePaths.onboardingOrganization);
        return;
      }

      if (!orgHasMembers) {
        setRoutePath(routePaths.onboardingMembers);
        return;
      }

      if (!orgHasLocations) {
        setRoutePath(routePaths.onboardingLocations);
        return;
      }

      if (!userIsActive) {
        setRoutePath(routePaths.onboardingPayment);
      }
    }
  }, [isLoading]);

  return {
    hasActiveSubscription,
    hasExistingDomain,
    isAdmin,
    isFirstTime,
    isLoading,
    isLocalOrg,
    isOrgAdmin,
    routePath,
    userIsActive,
  };
};
